@charset "UTF-8";

@font-face {
  font-family: "NotoSansCJKjp";
  font-weight: 400;
  font-style: normal;

  src: url("../../font/NotoSansCJKjp-Medium.woff");
  src: local("Noto Sans CJK JP Light"),
  url("../../font/NotoSansCJKjp-Medium.woff") format("woff"),
  url("../../font/NotoSansCJKjp-Medium.otf") format("opentype");
}

@font-face {
  font-family: "icomoon";
  font-weight: normal;
  font-style: normal;

  src: url("../../font/icomoon.eot?ng9vou");
  src: url("../../font/icomoon.eot?ng9vou#iefix") format("embedded-opentype"),
  url("../../font/icomoon.ttf?ng9vou") format("truetype"),
  url("../../font/icomoon.woff?ng9vou") format("woff"),
  url("../../font/icomoon.svg?ng9vou#icomoon") format("svg");
}

* {
  font-family: "Noto Sans", "Noto Sans CJK JP", sans-serif;
}

body {
  min-width: 1100px;
  @include sp {
    position: relative;
    min-width: 100%;
  }
}

.sp {
  display: none;
  @include sp {
    display: block;
  }
}